import { useState, useRef } from "react";
import { Row, Col, Form, Input, Button, Radio, Space ,message} from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
export default function UserAccountDeleteRequest() {

  const [showSuccess,setShowSuccess]=useState(false)

  const onFinish = (values) => {
    console.log("Received values:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
 const successBox=()=>{
  return (
    <div style={{width:"300px",height:"50px",backgroundColor:"white",boxShadow:"1px 2px 4px grey",zIndex:"9999"}}>
      <p>Success</p>
      <p>Thanks & Regards</p>
    </div>
  )
 }
  const submitForm = () => {
    //send data to server
   

    const formValues = formRef.current.getFieldsValue();
    console.log("Form Values:", formValues);
    axios.post("https://api.billerex.com/api/users/userAccountDeleteRequest",formValues,{
      headers: {
        'Content-Type': 'application/json'
      }}).then(res=>{
        console.log("data ==> ",res.data)
        if(res.data.status=="NOTEXIST")
          {
           message.error("we could not find any user ")
          }
        //setShowSuccess(true)
      }).catch(error=>{
        setShowSuccess(false)
       
           message.error("Internal server error")
          

        console.log("error ==> ",error)
      })
  };

  const [form] = Form.useForm();
  const formRef = useRef(null);

  return (
    <div
      style={{
        backgroundColor: "#7fc241",
        width: "100%",
        height: "100%",
        margin: "0px",
        padding: "40px",
      }}
    >
     {showSuccess? <showSuccess/>:""}
      <div
        style={{
          boxShadow: "1px 2px 4px grey",
          padding: "40px",
          backgroundColor: "white",
        }}
      >
        <div>
          <span style={{ color: "white", marginLeft: "10px" }}>
            <img
              width="150px"
              src="https://billerex.com/assets/img/logo.webp"
              alt="Logo"
            ></img>
          </span>
          <span style={{ color: "white" }}>brand</span>
        </div>
        <div style={{ marginTop: "160px" }}>
          <div style={{ fontFamily: "Arial, sans-serif" }}>
            <p
              style={{
                fontStyle: "italic",
                marginBottom: "20px",
                color: "grey",
                fontWeight: "bold",
                fontSize: "50px",
              }}
            >
              We're sad to see you leave! (Delete Your Account)
            </p>
            <p
              style={{
                marginBottom: "20px",
                lineHeight: "1.6",
                color: "grey",
                fontSize: "30px",
              }}
            >
              If you've decided to part ways with Billerex, we understand. We're
              here to help you through the process of deleting your account.
            </p>
            <div style={{ marginBottom: "20px" }}>
              <p
                style={{
                  marginBottom: "10px",
                  lineHeight: "1.6",
                  color: "grey",
                }}
              >
                <strong>Before You Proceed:</strong>
              </p>
              <ul
                style={{
                  listStyleType: "none",
                  paddingLeft: "0",
                  color: "grey",
                }}
              >
                <li style={{ marginBottom: "10px" }}>
                  <strong>
                    • Are you absolutely sure you want to delete your account?
                  </strong>{" "}
                  This action is irreversible and cannot be undone.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <strong>
                    • Instead of deleting, have you considered deactivating your
                    account?
                  </strong>{" "}
                  This will temporarily hide your profile from other users,
                  while still allowing you to reactivate it in the future if you
                  change your mind.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          ref={formRef}
        >
          <Row gutter={16}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input style={{ borderRadius: "20px" }} placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="user_id"
                rules={[
                  { required: true, message: "Please input your user ID!" },
                ]}
              >
                <Input.Password
                  style={{ borderRadius: "20px" }}
                  placeholder="User ID"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email ID!" },
                ]}
              >
                <Input
                  style={{ borderRadius: "20px" }}
                  placeholder="Email ID"
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input
                  style={{ borderRadius: "20px" }}
                  placeholder="Phone Number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <p>
                Would you consider reinstalling our app in the future if we
                address any concerns you have?
              </p>
              <Form.Item name="consider_reinstalling" rules={[{ required: true }]}>
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              We value your feedback and appreciate you taking the time to
              answer this brief questionnaire.
              <Form.Item name="reason" label="">
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value="" name="value">
                      {" "}
                      I have another app to use
                    </Radio>
                    <Radio value="not_interested">
                      I'm no longer interested in this app
                    </Radio>
                    <Radio value="difficult_to_use">
                      The app is too difficult to use
                    </Radio>
                    <Radio value="user_base">
                      I'm not comfortable with the user base
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="feedback">
                <TextArea
                  style={{ width: "100%", height: "100px" }}
                  placeholder="Your feedback here"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ marginTop: "30px" }}>
            <Button type="primary" onClick={submitForm}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div
        style={{
          backgroundColor: "#f9f7f1",
          padding: "20px",
          borderRadius: "10px",
          fontFamily: "Arial, sans-serif",
          boxShadow: "2px 4px 6px grey",
          margin: "10px",
          marginBottom: "10px",
        }}
      >
        <h2
          style={{ fontStyle: "italic", marginBottom: "20px", color: "#333" }}
        >
          Important Information:
        </h2>
        <ul style={{ listStyleType: "none", paddingLeft: "0", color: "#666" }}>
          <li style={{ marginBottom: "20px" }}>
            <strong>
              • Deleting your account will permanently remove your profile,
              photos, messages, and any other data associated with your account.
            </strong>
          </li>
          <li style={{ marginBottom: "20px" }}>
            <strong>
              • If you have any outstanding subscriptions, you will need to
              cancel them through your app store settings (e.g., Apple App
              Store, Google Play Store) before your account is deleted.
            </strong>
          </li>
          <li style={{ marginBottom: "20px" }}>
            <strong>
              • After your account is deleted, you will no longer be able to
              access the app.
            </strong>
          </li>
        </ul>
        <p style={{ lineHeight: "1.6", color: "#666" }}>
          We hope you had a positive experience with Billerex. We wish you all
          the best in your future endeavors!
        </p>
      </div>
    </div>
  );
}
