import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { PDFtoIMG } from "react-pdf-to-image";
import AxiosInstance from "../utils/AxiosInstance";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import TemplateCards from "../components/templateCards/templateCards";
import "../scss/bills.scss";

export default function Bills() {
  const { id } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [imagesArr, setImagesArr] = useState(null);
  // const [isCustom, setIsCustom] = useState(false);
  const [adIsTemplate, setAdIsTemplate] = useState(false);
  // const [isPos, setIsPos] = useState(false);
  // const [posData, setPosData] = useState(null);
  const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

  // console.log(id);

  const fetchInvoice = async (id) => {
    await AxiosInstance.post("api/invoice/getInvoiceWithAccessString", {
      invoice_access_string: id,
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          mapAds(res.data.data);
          setAdIsTemplate(res.data.data.ad_hasTemplate);
          console.log(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const mapAds = (data) => {
    // if (data.ads && data.ads.length > 0) {
    //   if (data.ads.ad_hasTemplate) {
    //     setIsCustom(false);
    //   } else {
    //     setIsCustom(true);
    //   }cl
    // }

    let tmpArr = [];
    // if (data.isPos) {
    //   // setIsPos(true);
    //   // var decodedString = atob(data.posData);

    //   // setPosData(decodedString);
    //   data.invoice_image.forEach((item) => {
    //     tmpArr.push(`${process.env.REACT_APP_BASE_URL}uploads/${item}`);
    //     // console.log(`${process.env.REACT_APP_BASE_URL}${item}`);
    //   });
    //   // setPosData(data.posData);
    // } else {
    console.log(data);
    // setIsPos(false);
    data.invoice_image.forEach((item) => {
      tmpArr.push(
        `${process.env.REACT_APP_BASE_URL}api/uploads/converted/${item}`
      );
      // console.log(`${process.env.REACT_APP_BASE_URL}${item}`);
    });
    // }
    // data.invoice_image.forEach((item) => {
    //   tmpArr.push(`${process.env.REACT_APP_BASE_URL}uploads/converted/${item}`);
    //   // console.log(`${process.env.REACT_APP_BASE_URL}${item}`);
    // });
    setImagesArr(tmpArr);
    setInvoiceData(data);
    console.log("initial data", data);
    // console.log(
    //   "template image",
    //   data.template[0].ad_templates.ad_template_image
    // );
  };

  useEffect(() => {
    fetchInvoice(id);
  }, []);

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        {/* <div className="col-1 d-flex align-items-center">
          {" "}
          {invoiceData && invoiceData.ads && invoiceData.ads.ad_left_banner && (
            <img
              src={`${process.env.REACT_APP_BASE_URL}uploads/${invoiceData.ads.ad_left_banner}`}
              alt=""
              className=" bill_ad_left bill_sideBills"
            />
          )}
        </div> */}
        <div className="col-10 col-md-8 ">
          <div className="row justify-content-center align-items-center">
            {!adIsTemplate ? (
              <div className="col-12 text-center mb-3">
                {invoiceData &&
                  invoiceData.ads &&
                  invoiceData.ads.ad_top_banner && (
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}api/uploads/${invoiceData.ads.ad_top_banner}`}
                      alt=""
                      className="bills_fullWidth"
                    />
                  )}
              </div>
            ) : (
              <div className="col-12 text-center mb-3">
                {invoiceData && invoiceData.template && (
                  <TemplateCards
                    image={
                      invoiceData.template[0].ad_templates.ad_template_image
                    }
                    text={invoiceData.template[0].ad_template_trans_head}
                    textColor={
                      invoiceData.template[0].ad_template_trans_head_color
                    }
                    viewOnly={true}
                  />
                )}
              </div>
            )}

            <div className="col-12 bills__mainDivStyle">
              <div
                className="row justify-content-center align-items-center"
                // dangerouslySetInnerHTML={{ __html: posData }}
              >
                {imagesArr ? (
                  <div className="">
                    {imagesArr.map((item) => {
                      return (
                        <div className="col-12 text-center">
                          <img src={item} alt="" className="bills_fullWidth" />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="row vh-100 vw-100 justify-content-center align-items-center">
                    <Spin indicator={antIcon} />
                  </div>
                )}
              </div>
            </div>
            {!adIsTemplate ? (
              <div className="col-12 text-center mt-3">
                {invoiceData &&
                  invoiceData.ads &&
                  invoiceData.ads.ad_bottom_banner && (
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}api/uploads/${invoiceData.ads.ad_bottom_banner}`}
                      alt=""
                      className="bills_fullWidth "
                    />
                  )}
              </div>
            ) : (
              <div className="col-12 text-center mt-3">
                {invoiceData && invoiceData.template && (
                  <TemplateCards
                    image={
                      invoiceData.template[0].ad_templates.ad_template_image
                    }
                    text={invoiceData.template[0].ad_template_trans_head}
                    textColor={
                      invoiceData.template[0].ad_template_trans_head_color
                    }
                    viewOnly={true}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {/* <div className="col-1 d-flex align-items-center">
          {invoiceData &&
            invoiceData.ads &&
            invoiceData.ads.ad_right_banner && (
              <img
                src={`${process.env.REACT_APP_BASE_URL}uploads/${invoiceData.ads.ad_right_banner}`}
                alt=""
                className="bill_sideBills bill_ad_right"
              />
            )}
        </div> */}
      </div>
    </div>
  );
}
