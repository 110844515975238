import "./templateCards.scss";
export default function TemplateCards({
  id,
  selected,
  image,
  text,
  textColor,
  fontSize,
  handleTemplateChange,
  viewOnly,
}) {
  console.log(text);
  console.log(fontSize);
  return (
    <div
      className={`container-fluid ${
        !viewOnly ? "templateCard__containerWrapper" : ""
      } ${selected ? "shadow border" : ""}`}
      onClick={() => handleTemplateChange(id)}
    >
      <div className="templateCard__wrapper">
        <div className="position-relative ">
          <img
            src={`${process.env.REACT_APP_BASE_URL}api/uploads/${image}`}
            alt=""
            className="position-relative templateCard__imgStyle"
          />
          <div
            className="templateCards__textStyle"
            style={{ color: textColor, fontSize: `${fontSize}px` }}
          >
            {text}
          </div>
        </div>
      </div>
    </div>
  );
}
