import "./App.css";
import "antd/dist/antd.css";
import React, { Suspense, lazy } from "react";
import { Spin } from "antd";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import AdminLayout from "./layouts/layout";
import Bills from "./pages/bills";
import UserAccountDeleteRequest from "./pages/userAccountDeleteRequest";
const Dashboard = lazy(() => import("./pages/dashboard"));
const Categories = lazy(() => import("./pages/categories"));
const SubCategories = lazy(() => import("./pages/subCategories"));
const Shops = lazy(() => import("./pages/shops"));
const Login = lazy(() => import("./pages/login"));
const Users = lazy(() => import("./pages/users"));
const Invoices = lazy(() => import("./pages/invoices"));
const Packages = lazy(() => import("./pages/packages"));
const Subscriptions = lazy(() => import("./pages/subscriptions"));
const POS = lazy(() => import("./pages/pos"));
const License = lazy(() => import("./pages/license"));
const Password = lazy(() => import("./pages/Password"));

const Ads = lazy(() => import("./pages/ads"));
const AdsTemplates = lazy(() => import("./pages/adsTemplates"));
const Promotions = lazy(() => import("./pages/promotions"));

// const Bills = lazy(() => import("./pages/bills"));

function App() {
  return (
    <Router>
      <Suspense
        fallback={
          <AdminLayout>
            <div className="container-fluid vh-100">
              <div className="row vh-100 justify-content-center align-items-center">
                <Spin size="large" />
              </div>
            </div>
          </AdminLayout>
        }
      >
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/UserAccountDeleteRequest"
            element={<UserAccountDeleteRequest />}
          />
          <Route path="/Categories" element={<Categories />} />
          <Route path="/Subcategories" element={<SubCategories />} />
          <Route path="/Shops" element={<Shops />} />
          <Route path="/login" element={<Login />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/Invoices" element={<Invoices />} />
          <Route path="/Packages" element={<Packages />} />
          <Route path="/Subscriptions" element={<Subscriptions />} />
          <Route path="/Pos" element={<POS />} />
          <Route path="/Licenses" element={<License />} />
          <Route path="/Password" element={<Password />} />
          <Route path="/Ads" element={<Ads />} />
          <Route path="/AdsTemplates" element={<AdsTemplates />} />
          <Route path="/Bills/:id" element={<Bills />} />
          <Route path="/Promotions" element={<Promotions />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
