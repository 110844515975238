const isLoggedIn = () => {
  if (window.localStorage.hasOwnProperty("token")) {
    return true;
  } else {
    return false;
  }
};

const storeToken = (token) => {
  window.localStorage.setItem("token", token);
};

const getToken = (token) => {
  if (window.localStorage.hasOwnProperty("token")) {
    return window.localStorage.getItem("token");
  } else {
    return "";
  }
};

const removeToken = () => {
  if (window.localStorage.hasOwnProperty("token")) {
    window.localStorage.removeItem("token");
  }
};

module.exports = { getToken, storeToken, isLoggedIn, removeToken };
